(function() {
    'use strict';

    angular
        .module('common')
        .factory('OrganizationRoleService', OrganizationRoleService);

    function OrganizationRoleService() {
        return {
            getOrganizationRoles: getOrganizationRoles
        };

        function getOrganizationRoles() {
            return [{
                name: 'Org Admin',
                id: 'orgadmin'
            }, {
                name: 'Basic User',
                id: 'user'
            }];
        }
    }
})();
